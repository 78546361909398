@import "../theme/colors";

.media-right {
  .btn-danger {
    margin-top: 5px;
  }
}

// .notice-content{
//   max-width: 100%;
//   max-height: 100%;
// }

.img-notice {
  min-width: 800px;
  max-height: 100%;
  min-height: 534px;
  padding: 50px 50px 50px 250px;
  color: $color-black;
  p {
    font-size: 16px;
    color: $color-black;
  }
}
.text-notice{
  max-width: 700px;
  max-height: 500px;
  overflow: hidden;
}