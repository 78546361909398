.fixed-height-primary-module {
  color: $white;
  height: 290px;
}

// + en bas des blocs
.fixed-position-plus {
  position: absolute;
  bottom: 20px;
  right: 35px;
}