.icon {
  text-decoration: none;
  font-size: 22px;
  line-height: 22px;
  &.bigger {
    font-size: 50px;
    line-height: 50px;
    &:before {
      color: $gray-light-shadow;
    }
  }
  &:before {
    color: white;
    margin-right: 3px;
    margin-left: 3px;
  }
}

a.icon {
  text-decoration: none;
  padding-top: 7px;
  padding-bottom: 7px;
  .navbar-nav > li > & {
    line-height: 22px;
    border: none;
  }
}

@mixin apply-color($color, $size, $padding-left, $padding-right, $padding-bottom: 0px) {
  color: $color;
  &.icon {
    color: $color;
    font-size: $size;
    vertical-align: middle;
   //@include border-radius(4px);
    &:before {
      color: $color;
      text-decoration: none !important;
      margin-right: $padding-right;
      margin-left: $padding-left;
      margin-bottom: $padding-bottom;
    }
  }
  a, a:hover, a:focus {
    color: $color;
    text-decoration: none;
  }
}
.icon-minus-thin:before {
  content: "\e956";
}

span.icon {
  text-decoration: none !important;
  vertical-align: middle;
  &:before, &:hover {
    text-decoration: none;
  }
}
a> p > span.icon:hover  {
  text-decoration: none !important;
}

.icon-button {
  @include apply-color($white, 22px, 4px, 10px, 20px);
}
.icon-black {
  @include apply-color($black, 22px, 0px, 10px);
}

.icon-black-thin {
  @include apply-color($black, 22px, 2px, 2px);
}

.icon-primary {
  @include apply-color($color-sfvp-primary, 22px, 10px, 10px);
}

.icon-primary-big {
  @include apply-color($color-sfvp-primary, 50px, 10px, 10px);
}

.icon-primary-thin {
  @include apply-color($color-sfvp-primary, 22px, 2px, 2px);
}

.icon-caret {
  @include apply-color($black, 16px, 10px, 10px);
}

.icon-h1 {
  @include apply-color($color-sfvp-darker, 30px, 10px, 10px);
}