.pdf {
  height: 768px;
  width: 1095px;
  margin: 20px;
}
.img-pdf {
  margin-bottom: 20px;
}
.row.row-pdf {
  margin:0;
  border-left: $brand-primary 20px solid;
  border-right: $brand-primary 20px solid;
}

.portrait {
  height: 1122px !important;
  width: 793px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}