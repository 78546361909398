.relative-element {
    position: relative;
}

.fixed-element {
    position: fixed;

    &.preview-link {
        bottom: 40%;
        left: #{- $padding-cln / 3 * 2};

        z-index: $zindex-navbar-fixed;
    }

    &.next-link {
        bottom: 40%;
        right: #{- $padding-cln / 3 * 2};

        z-index: $zindex-navbar-fixed;
    }
}

.d-flex{
    display: flex;
}
.flex-column{
    flex-direction: column;
}
.d-none{
    display: none;
}
.align-center{
    align-items: center;
}

.position-relative{
    position: relative;
}
.position-absolute{
    position: absolute;
}