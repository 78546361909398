﻿h1,
h2,
h3,
h4,
h5,
h6,
.like-h1,
.like-h2,
.like-h3,
.like-h4,
.like-h5,
.like-h6 {
    font-family: $font-sfvp-muli;
}

.like-h1 {
    font-size: $font-size-h1 !important;
}

.like-h2 {
    font-size: $font-size-h2 !important;

    @media (min-width: $screen-md-min) {
        &_md {
            font-size: $font-size-h2 !important;
        }
    }

    @media (min-width: $screen-sm-min) {
        &_sm {
            font-size: $font-size-h2 !important;
        }
    }
}

.like-h3 {
    font-size: $font-size-h3 !important;

    @media (min-width: $screen-md-min) {
        &_md {
            font-size: $font-size-h3 !important;
        }
    }

    @media (min-width: $screen-sm-min) {
        &_sm {
            font-size: $font-size-h3 !important;
        }
    }
}

.like-h4 {
    font-size: $font-size-h4 !important;

    @media (min-width: $screen-md-min) {
        &_md {
            font-size: $font-size-h4 !important;
        }
    }

    @media (min-width: $screen-sm-min) {
        &_sm {
            font-size: $font-size-h4 !important;
        }
    }
}

.like-h5 {
    font-size: #{$font-size-h5 /$font-size-root}rem !important;
}
