.form-control {
  height: 40px;
  border:none;
  color: $color-sfvp-primary;
}
input::placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder {
  color: $color-sfvp-primary;
}

label {
  color: $color-sfvp-primary;
  text-transform: uppercase;
  &.normal {
    color: $color-black_80;
    text-transform: none;
  }
}

.input-group-addon {
  background-color: $color-sfvp-primary;
  color: $white;
}

.easyadmin-vich-file {
  label {
    text-transform: none !important;
  }
}