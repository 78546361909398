@import "../theme/colors";
@import "variables";

.list-social-network li {
    padding-bottom: $literal-spacing_min;
}

.btn.btn_social-network {
    border: 0;

    color: $white;

    font-weight: bold;

    &.btn {
        &_facebook {
            background-color: $blue_facebook;
        }

        &_google {
            background-color: $red_google;
        }

        &_twitter {
            background-color: $blue_twitter;
        }
    }
}
