//p.list-number {
//  color: $color-sfvp-darker;
//  font-weight: bold;
//  margin-bottom:0px;
//
//}
span.list-number {
  font-size: 36px;
  font-weight: bold;
  color: $color-sfvp-darker;
  padding-right: 10px;
}

/*.arrow-box {
  // fleche
  border-color: #fff;
  border-left-color: rgb(255, 255, 255);
  border-style: solid;
   margin: 20px;

  &.arrow-right {
    border-top-width: 7px;
    border-bottom-width: 7px;
    border-left-width: 10px;
    border-left-color: $black;
  }
}
*/

a.list-number, p.list-number {
  padding-right:20px;
  line-height:20px;
  font-weight: bold;
  color: $color-sfvp-darker;
  &:hover, &:focus {
    color: $link-hover-color;
  }


  &:before {
    content: attr(data-description);
    position: absolute;
    top:0px;
    left: -30px;
    font-size: 40px;
    line-height: 40px;
    //margin-right: 4px;
    //font-weight: bold;
    color: $color-sfvp-darker;
  }
}

.arrow_box, .no-arrow {
  position: relative;
  padding: 15px 15px 20px;
  &.push-right {
    padding-left: 30px;
  }
  .active {
    background: $color-sfvp-blue;
  }
  border-left: 1px solid #c2e1f5;
  border-bottom: 1px solid #c2e1f5;
}
.arrow_box:after, .arrow_box:before {
  //left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: $color-sfvp-blue;
  border-width: 39px;
  margin-top: -39px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: #c2e1f5;
  border-width: 37px;
  margin-top: -37px;
}


//i {
//  border: solid black;
//  border-width: 0 3px 3px 0;
//  display: inline-block;
//  padding: 3px;
//}
//
//.right {
//  transform: rotate(-45deg);
//  -webkit-transform: rotate(-45deg);
//}

ul.document-list {
  color: $brand-primary;
}