@import "../theme/colors";

.effect {
    &_shadow {
        position: relative;

        &:before,
        &:after {
            position: absolute;
            top: 10%;
            bottom: 15px;
            left: 10px;

            z-index: -1;

            max-width: 300px;
            width: 100%;

            background: $gray-light-shadow;

            content: "";

            box-shadow: 0 15px 10px #{$gray-light-shadow};

            transform: rotate(-3deg);
        }

        &:after {
            right: 10px;
            left: auto;

            transform: rotate(3deg);
        }
    }
}