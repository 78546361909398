.custom-button {
  height: 60px;
  width: 100%;
  max-width: 312px;
  background-color: $color-sfvp-blue-light;
  border : none;
  border-top: $color-sfvp-aqua solid 4px;
  padding-left:25px;
  margin-bottom : 25px;
  p {
    text-align: left;
    line-height: 22px;
    font-family: $font-sfvp-muli;
    font-size: 20px;
    font-weight: $font-sfvp-weight-regular;
    color: $color-sfvp-aqua;
    .text-light {
      font-family: $font-sfvp-muli;
      font-size: 16px;
    }
  }
  &.inverse {
    background-color: $color-sfvp-aqua;
    border-top: $color-sfvp-green-light solid 4px;
    p {
      color: $color-sfvp-green-light;
    }
  }
}

.logo-btn{
  max-height: 45px;
  max-width: 55px;
}