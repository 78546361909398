.header-banner {
  min-height: 190px;
  color: white;
  margin-bottom: 30px;
  align-items: center;
  text-transform: uppercase;
  h1.title--secondary {
    margin: 0;
    text-transform: uppercase;
    font-weight: 200;
    color: white !important;
    line-height: 190px;
    font-size: 3em;
  }
}