// Import Bootstrap Compass integration
@import "bootstrap_custom";
@import "theme/colors";
@import "theme/fonts";

// import font Awesome
@import "fontawesome/font-awesome";

// import fonts
@import "icomoon/style";
@import "montserrat/style";

@import "theme/embed-fonts";
@import "base/typo";
@import "base/button";
@import "base/icon";

@import "utils/main";

@import "layout/base";
@import "layout/footer";
@import "layout/nav";
@import "layout/pdf";
@import "layout/img";
@import "layout/form";
@import "layout/listnumber";
@import "layout/panel";
@import "layout/breadcrumb";

@import "module/tontine";
@import "module/condolence";
@import "module/notice";
@import "module/appointment";
@import "module/bootstrap_social";
@import "module/login";
@import "module/bloc";
@import "module/obituary";
@import "module/404";
@import "module/bloc_button";
