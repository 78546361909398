@import "../../theme/colors";

// General bg styles
.bg {
    &_white {
        background-color: $white;
    }

    &_pink {
        background-color: $pink;
    }

    &_primary {
        background: $color-sfvp-primary;
    }

    &_dark_primary {
        background: $color-sfvp-darker;
    }

    &_blue_light {
        background: $color-sfvp-blue-light;
    }

    &_blue_lighter {
        background: $color-sfvp-blue-lighter;
    }

    &_blue {
        background: $color-sfvp-blue;
    }

    &_yellow {

        &,
        &_hover:hover,
        &_active.active,
        .active>&_active {
            background-color: $yellow;
        }

        &,
        .btn-link {
            color: $gray;
        }

        &.text_white {
            color: $white;
        }

        .btn-link {

            &:hover,
            &:active,
            &:focus {
                color: $white;
            }
        }

        .btn:focus {
            color: $white;
        }
    }

    &_blue-highlight {

        &,
        &_hover:hover,
        &_active.active,
        .active>&_active {
            background-color: $blue-highlight;
        }

        &.btn {
            &:hover {
                background-color: darken($blue-highlight, 7%);
            }
        }

        &,
        .btn-link {
            color: $white;
        }
    }

    &_white-dark {
        background-color: $white-dark;

        &_hover:hover {
            background-color: $white-dark;
        }
    }

    &_gray-lighter {
        background-color: $gray-lighter;
    }

    &_gray-light {
        background-color: $gray-light;
    }

    &_gray-light-shadow {
        background-color: $gray-light-shadow;
    }

    &_gray {
        background-color: $gray;

        &_hover:hover {
            background-color: $gray;
            color           : $white;
        }

        &-dark {
            background-color: $gray-dark;

            &_opacity {
                background-color: rgba($gray-dark, .7);
            }
        }
    }

    &_black {
        background-color: $black;

        &_opacity {
            background-color: rgba($black, .5);
        }
    }

    &_bordeaux {
        background-color: $bordeaux;
    }

    &_repos_digital {
        background-color: $color-repos-digital;
    }
}