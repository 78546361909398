$font-sfvp-size-nav: 16px;
$font-sfvp-size-head: 24px;
$font-sfvp-size-h1: 1.5*$font-sfvp-size-head;
$font-sfvp-size-main-nav: 1.5*$font-sfvp-size-nav; //28px
$font-sfvp-size-top-nav: 1.3*$font-sfvp-size-nav;// 24px

$font-sfvp-relative-size: 1;
$font-sfvp-weight-regular: Normal;
$font-sfvp-weight-bold: Bold;
$font-sfvp-weight-thin: 100;

$font-sfvp-muli : 'Muli', sans-serif;
$font-sfvp-family-frutiger-roman: Conv_FrutigerRoman, Arial, sans-serif;
$font-sfvp-family-frutiger-light: Conv_FrutigerLight, Arial, sans-serif;

$line-height-sfvp: 1.3333; // 24/18
$line-height-nav: 0.5;
$line-height-header: 1.5em;
$font-size-btn: $font-sfvp-relative-size;
$font-size-p: $font-sfvp-relative-size;
$font-size-blockquote-icon: 2.5;

// dans base/_forms de bootstrap, la taille $font-sfvp-size est appliquée sur tous les éléments d'un formulaire
// selecteur suivant : .form-control, input[type="text"], input[type="password"], ... select, textarea
$font-size-field: $font-sfvp-relative-size;

@mixin text-format ($font-weight, $font-size) {
  font-weight: $font-weight;
  font-size: $font-size;
}