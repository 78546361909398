@import "../../theme/bootstrap-variables";

.page-content {
    .container-fluid {
        padding: #{$container-fluid-padding-vertical / $font-size-base}em #{$padding-cln / $font-size-base}em;

        &_max-width {
            margin: 0 auto;
            max-width: $content-max-width;
        }

        &_fullscreen {
            width: 100%;

            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &_spacing-min {
            padding-top: $container-fluid-padding-min;
            padding-bottom: $container-fluid-padding-min;
        }

        &_spacing-max {
            padding-top: $container-fluid-padding-max;
            padding-bottom: $container-fluid-padding-max;
        }
    }
}