a {
    &:focus,
    &:hover,
    &:active {
        // border: 0;
        outline: none;
    }
    &.underline {
        text-decoration: underline;
        color: $link-hover-color;
        span, span:before {
            text-decoration: none;
        }
    }

    &.underline_primary {
        text-decoration: underline;
        color: $color-sfvp-primary;
        span, span:before {
            text-decoration: none!important;
        }
    }
    &.disabled,
    &[disabled] {
        cursor: not-allowed;
        pointer-events: none;
    }

    &.reverse,
    &.reverse:hover,
    &.reverse:focus{
        color: $white;
    }
}
.decoration-hover:hover{
    text-decoration: underline!important;
}
