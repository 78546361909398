@font-face {
  font-family: 'Conv_FrutigerLight';
  src: url('../../fonts/frutiger/FrutigerLight.eot');
  src: local('?'), url('../../fonts/frutiger/FrutigerLight.woff') format('woff'), url('../../fonts/frutiger/FrutigerLight.ttf') format('truetype'), url('../../fonts/frutiger/FrutigerLight.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Conv_FrutigerBold;
  src: url('../../fonts/frutiger/FrutigerBold.eot');
  src: local('?'), url('../../fonts/frutiger/FrutigerBold.woff') format('woff'), url('../../fonts/frutiger/FrutigerBold.ttf') format('truetype'), url('../../fonts/frutiger/FrutigerBold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Conv_FrutigerCondensedLight;
  src: url('../../fonts/frutiger/FrutigerCondensedLight.eot');
  src: local('?'), url('../../fonts/frutiger/FrutigerCondensedLight.woff') format('woff'), url('../../fonts/frutiger/FrutigerCondensedLight.ttf') format('truetype'), url('../../fonts/frutiger/FrutigerCondensedLight.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Conv_FrutigerCondensedRoman;
  src: url('../../fonts/frutiger/FrutigerCondensedRoman.eot');
  src: local('?'), url('../../fonts/frutiger/FrutigerCondensedRoman.woff') format('woff'), url('../../fonts/frutiger/FrutigerCondensedRoman.ttf') format('truetype'), url('../../fonts/frutiger/FrutigerCondensedRoman.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Conv_FrutigerLightItalic;
  src: url('../../fonts/frutiger/FrutigerLightItalic.eot');
  src: local('?'), url('../../fonts/frutiger/FrutigerLightItalic.woff') format('woff'), url('../../fonts/frutiger/FrutigerLightItalic.ttf') format('truetype'), url('../../fonts/frutiger/FrutigerLightItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Conv_FrutigerRoman;
  src: url('../../fonts/frutiger/FrutigerRoman.eot');
  src: local('?'), url('../../fonts/frutiger/FrutigerRoman.woff') format('woff'), url('../../fonts/frutiger/FrutigerRoman.ttf') format('truetype'), url('../../fonts/frutiger/FrutigerRoman.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}