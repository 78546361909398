.footer {
  border-top: solid 1px $white-border-grey;
  margin-top: $very-large-margin;
  p {
    font-size: $font-size-root;
    color: $gray-lighter;
    line-height: 2em;
  }
  a {
    color: $gray-dark;
  }
  .nav > li > a:hover, .nav > li > a:focus {
    text-decoration: underline;
    background: none;
  }
}
