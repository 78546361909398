.block-tontine {
  //position: relative;
}
.tontine input[type=submit],
.participation input[type=submit],
.paiement__sidebar input[type=submit] {
  width: 100%;
  //font-size: 1.1em;
  //padding: 10px;
  //margin-top: 10px;
  //color: $white;
  //background-color: $color-sfvp-orange-light;
  //border-radius: 4px;
  //border: none;
  //-webkit-box-shadow: 0 2px 0 $color-sfvp-orange;
  //box-shadow: 0 2px 0 $color-sfvp-orange;
  //text-transform: uppercase;
  //cursor: pointer;
}

/* FORMULAIRE CREATION CAGNOTTE */
.tontine, #paiement_tontine {
  display: inline-block;
  margin-top: 15px;
  //font-family: helvetica, sans-serif;
  font-size: 1em;
  background-color:$white;
  padding: 20px;
  border-top: 5px solid $color-sfvp-blue;
  background: rgba(255,255,255,1);
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(47%, rgba(246,246,246,1)), color-stop(100%, rgba(237,237,237,1)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed', GradientType=0 );
  box-shadow: 1px 1px 6px rgba(0,0,0,0.3);
  width: 91%;
}

.tontine h1, .participation h1 {
  text-transform: uppercase;
  text-align: center;
  margin:0 0 10px 0;
  color: $color-sfvp-blue;
  text-shadow: 0 2px 1px $color-sfvp-aqua;
}
.price_subtitle {
  font-weight: 600;
  margin-bottom: 10px;
  color: $color-sfvp-blue;
  text-align: center;
}

.tontine label {
  margin-bottom: 5px;
  display: block;
  font-weight: 600;
}

.tontine input[type=text],
.tontine select {
  box-shadow: inset 0px 1px 2px rgba(0,0,0,0.4);
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px 0 10px 10px;
  //margin-bottom: 20px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.participation input[type=text] {
  box-shadow: inset 0px 1px 2px rgba(0,0,0,0.4);
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.participation select {
  display: inline-block;
  font-size: medium;
  padding: 10px 0 10px 10px;
  margin: 10px 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: rgba(246,246,246,1);
  box-shadow: inset 0px 1px 5px rgba(0,0,0,0.4);
  border: none;
  border-radius: 4px;
  width: auto;
  height: auto;

}

.paiement__sidebar input[type=submit] {width: 100%;margin-top: 0;}
/* FIN FORMULAIRE CREATION CAGNOTTE */

.payment-methods {
  padding-top:10px;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  li {
    display: inline-block;
  }
}

#paiement_tontine h1 {
  text-transform: uppercase;
  text-align: center;
  margin:0 0 20px 0;
  color: $color-sfvp-blue;
  text-shadow: 0 2px 1px $color-sfvp-aqua;
}

#paiement_tontine p {
  margin-top: 5px;
  font-size: 12px;
  opacity: 0.5;
}

#paiement_tontine label {
  color: #646464;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

#paiement_tontine input[type=text],
#paiement_tontine select{
  box-shadow: inset 0px 1px 2px rgba(0,0,0,0.4);
  border: none;
  border-radius: 4px;
  width: 100%;
  text-align:center;
  font-size: 1em;
}

#paiement_tontine input[type=text],
#paiement_tontine select{
  padding: 10px 0;
  margin-bottom: 20px;
}

// box à droite du form participation
.participation {
  //font-family: $font-sfvp-family-frutiger-roman;
  //min-height: 325px;
  background: $white;
  box-shadow: 1px 1px 6px rgba(0,0,0,0.3);
  strong {
    color: #000;
  }
  h3 {
    padding:10px;
    margin-top: 15px;
    color: #646464;
    border-top: 4px solid $color-sfvp-blue;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%);
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    .fa {
      color: $color-sfvp-blue;
    }
  }
}

.message__content {
  padding: 20px;
  text-align: center;
}
.message__content span {
  display: block;
  color: $color-sfvp-blue;
  font-size:20px;
  font-weight: bold;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.4);
}
.paragraphe__content {
  height: 170px;
  margin-top: 10px;
  background: #e0e0e0;
}
.paragraphe__content p {
  padding: 10px 0;
  color: #646464;
}
.stats__content {
  color: #646464;
  padding: 0 20px 20px 20px;
}

#copy-link-btn {
  transition: ease-in-out;

  &:hover {
    cursor: pointer;
  }
}
