.img-condolence {
  float: right;
  margin: 15px;
  border-radius: 5px;
}
.introduction {
  padding-top:10px;
  background: $color-sfvp-blue-light;
}

.book-color {
  color: $book-color;
}
.leftBorder{
  border-left: 2px $color-sfvp-primary solid;
}