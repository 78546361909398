button.style-reset {
    background: none;

    border: 0;
}

button.btn-left {
    text-align: left !important;
    letter-spacing: 1px;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
    opacity: 0.20;

}
