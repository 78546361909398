/*
*   name           l000355
*   version        1.0
*   copyright      Copyright (C) 2016 CFD Technologies. All Rights Reserved.
*   license        Licensed under GNU/GPLv3 (http://www.gnu.org/licenses/gpl-3.0.html)
*/

@import "../utils/variables";

/* maximum width */
.container {
  //margin: 0 auto;
  @media (min-width: 1200px) {
    width: $content-max-width;
  }

  &.big-border {
    border-top: $brand-primary $top-border-height solid;
  }
  //@media (max-width: 768px) {
  //  padding: 5px;
  //}
}
.big-border {
  border-top: $brand-primary $top-border-height solid;
}
.container-fluid {
  &.big-border {
    border-top: $brand-primary $top-border-height solid;
  }
  margin-right: auto;
  margin-left: auto;
  padding-left: 7%;
  padding-right: 7%;
  @media (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.main-content {
  padding: 10px;
}

.logo {
  @media (max-width: 768px) {
    img {
      padding:10px;
    }
  }
}

.good-to-know {
  padding:0px 20px 20px 20px;
  margin-top:20px;
  border: #6c1220 solid 2px;
  ul {
    list-style: none; /* Remove default bullets */
  }

  ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #6c1220; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
  h3 {
    color:#6c1220;
  }

}