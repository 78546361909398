// Utils to center block element in parent block
@mixin element-center-block() {
    display: block !important;
    margin: auto !important;

    float: none !important;
}

.element-center {
    @include element-center-block();

    &_xs {
        @media(max-width: $screen-xs-max) {
            @include element-center-block();
        }
    }
}

// Vertical align
.vertical-align-table {
    width: calc(100% + #{$padding-cln * 2});
    margin: 0  #{- $padding-cln};

    &_xs {
        display: table;

        .vertical-align-table__cell {
            display: table-cell;

            float: none;
        }
    }

    &_sm {
        @media (min-width: $screen-sm-min) {
            display: table;

            .vertical-align-table__cell {
                display: table-cell;

                float: none;
            }
        }
    }

    &_md {
        @media (min-width: $screen-md-min) {
            display: table;

            .vertical-align-table__cell {
                display: table-cell;

                float: none;
            }
        }
    }

    &_lg {
        @media (min-width: $screen-lg-min) {
            display: table;

            .vertical-align-table__cell {
                display: table-cell;

                float: none;
            }
        }
    }

    &_row {
        &_xs {
            @media (max-width: $screen-xs-max) {
                display: table-row;
            }
        }

        &_sm {
            @media (min-width: $screen-sm-min) {
                display: table-row;
            }
        }

        &_md {
            @media (min-width: $screen-md-min) {
                display: table-row;
            }
        }

        &_lg {
            @media (min-width: $screen-lg-min) {
                display: table-row;
            }
        }
    }

    .vertical-align-table__cell {
        &_middle {
            vertical-align: middle;
        }

        &_top {
            vertical-align: top;
        }

        &_bottom {
            vertical-align: bottom;
        }
    }
}
