label {
  text-transform: unset;
}

.obituary-preview {
  background-color: #d2e7eb;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .age {
    font-size: 20px;
  }

  .obituary-footer {
    margin-left: -10px;
    margin-top: 25px;
  }
}