@CHARSET "UTF-8";

// Charte SFVP

$color-sfvp: #000;

$color-black: #000;
$color-black_80: rgba(0, 0, 0, 0.8);
$color-black_50: rgba(0, 0, 0, 0.5);
$color-black_40: rgba(0, 0, 0, 0.4);
$color-black_20: rgba(0, 0, 0, 0.2);
$color-black_15: rgba(0, 0, 0, 0.15);
$color-black_10: rgba(0, 0, 0, 0.1);
$color-white_40: rgba(255,255,255,0.4);
$color-grey: #aab8c2;

$book-color: #4ab693;

$black: #000000;
$gray-darker: #222222;
$gray-dark: #323232;
$gray: #444444;
$gray-light-shadow: #777;
$gray-lighter: #aaaaaa;
$white-darker: #cccccc;
$white-border-grey: #e7e7e7;
$white-dark: #f9f9f9;
$white: #ffffff;


// couleur des boutons
$color-sfvp-primary: #1c8ba6;
$color-sfvp-darker: #115566;
// fond de l'encart connexion et fond espace
$color-sfvp-blue-light: #f4f9fa;
// sidebar blue
$color-sfvp-blue: #d2e7eb;
$color-sfvp-blue-lighter: #D6EEFF;
// flower blue
$color-sfvp-blue-flower: #d7eeff;




//$color-sfvp-text-button: #436e7e;
$color-sfvp-green-light: #88A465;

$color-sfvp-nav: #426D7D;
$color-sfvp-aqua: #426D7D;

$color-sfvp-red-light: #ef4454; //#ea4335
$color-sfvp-orange: #e46000;
$color-sfvp-orange-light: #ff974b;
$color-sfvp-green: #65b22c;
$color-sfvp-yellow: #f7ff3c;

$blue_facebook: #3b5999;
$blue_twitter: #00aced;
$red_google: #dd4b39;


$green: green;
$green-light: #27ae60;

$yellow: #ffc926;

$orange-red: #ff6438;
$red-orange: #eb5b33;
$red: red;
$pink: #fb3c6b;
$bordeaux: #6c1220;
$blue: #00528e;
$blue-highlight: #0099cc;
$color-repos-digital: rgb(62, 181, 232);



$light-border-color: #eceae2 #eceae2 #dddbd0 #eceae2;



