.plume-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  background: url('../../images/plume-condoleances.png') bottom left no-repeat;
  min-height: 100%;
  width: 100%;
  opacity: 0.4;
}

