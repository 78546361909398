@import "../theme/colors";

.transition {
    transition: all 300ms ease;
}

.box-shadow {
    &.box-shadow_default {
        box-shadow: rgba($black, .3) 0 1px 1px;
    }

    &.box-shadow_white-darker {
        box-shadow: 0 0 4px #{$white-darker};

        &_large,
        &_large_hover:hover {
            box-shadow: 0 0 8px #{$white-darker};
        }
    }
}

.circle {
    border-radius: 50%;
}

.border-radius {
    &_top {
        border-radius: #{$padding-cln} #{$padding-cln} 0 0;
    }

    &_right {
        border-radius: 0 #{$padding-cln} #{$padding-cln} 0;
    }

    &_bottom {
        border-radius: 0 0 #{$padding-cln} #{$padding-cln};
    }

    &_left {
        border-radius: #{$padding-cln} 0 0 #{$padding-cln};
    }
}
