.navbar-default {
  background: $white;
  margin-bottom: 0;
  border: none;

  .personal-space {
    position:relative;
    padding: 15px;
    top: -$top-border-height;
    margin-bottom: -$top-border-height +10px;
    background: $color-sfvp-darker;
    min-height: 120px;
    width: 93px;
    > a {
      color: $white;
      .fa:before {
        font-size: 28px;
      }
    }
  }
  .bg_primary {
    background: $color-sfvp-primary;
  }

}

.navbar-default .navbar-nav {
  //padding-left: 20px;
  padding-right: 20px;
  > li > a {
    padding-top: 30px;
    color: $color-sfvp-primary;
    text-decoration:underline;
  }
  a {
    color: $color-sfvp-primary;
  }
}
