.bg {
    &_cover {
        background-position: center;
        background-repeat: none;
        background-size: cover;
    }

    // home page connexion
    &_page-header {
        background-image: url('../../../images/bg_header.png');
        height: 550px;

        @media (max-width: $screen-sm-max) {
            background: none;
        }
    }

    &_flower {
        background: $color-sfvp-blue-flower url('../../../images/bandeau-fleur.jpg') no-repeat;
        height: 80px;
    }

    &_plume {
        background:  url('../../../images/plume-entete-condoleances.png') bottom left no-repeat;
        min-height: 220px;
    }

    &_book {
        background:  url('../../../images/book-plume.jpg') bottom left no-repeat;
    }

    &_fleche1 {
        background-image: url('../../../images/navigation-step-one.jpg');
        height: 80px;
        @media (max-width: $screen-sm-max) {
            background: none;
        }
    }
    &_fleche2 {
        background-image: url('../../../images/navigation-step-two.jpg');
        height: 80px;
        @media (max-width: $screen-sm-max) {
            background: none;
        }
    }
    &_fleche3 {
        background-image: url('../../../images/navigation-step-three.jpg');
        height: 80px;
        @media (max-width: $screen-sm-max) {
            background: none;
        }
    }
}

@mixin bg-img-four-formats($urlPrefix, $ext: 'jpg')
{
    $url-lg: "#{$urlPrefix}_lg.#{$ext}";
    background-image: url($url-lg);

    @media (max-width: $screen-md-max) {
        $url-md: "#{$urlPrefix}_md.#{$ext}";
        background-image: url($url-md);
    }

    @media (max-width: $screen-sm-max) {
        $url-sm: "#{$urlPrefix}_sm.#{$ext}";
        background-image: url($url-sm);
    }

    @media (max-width: $screen-xs-max) {
        $url-xs: "#{$urlPrefix}_xs.#{$ext}";
        background-image: url($url-xs);
    }
}

// Page header's background
.bg {


    //@include bg-img-four-formats('../../../images/earth_blue');
    //@include bg-img-four-formats('../../../images/earth_black');

}
