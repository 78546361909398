@import "../../theme/colors";

.text {
    &_white {
        color: $white !important;

        & .text_secondary {
            font-size: .8em;

            color: rgba($white, .7);
        }
    }

    &_primary{
        color: $color-sfvp-primary;
    }

    &_yellow {
        color: $yellow !important;
    }

    &_gray {
        color: $gray !important;

        &-light {
            color: $gray-light !important;
        }

        &-light-shadow {
            color: $gray-light-shadow !important;
        }

        &-dark {
            color: $gray-dark !important;
        }

        &-darker {
            color: $gray-darker !important;
        }
    }

    &_green {
        color: $green !important;
    }

    &_red {
        color: $red !important;
    }
}

// Specification for links
a.text {
    &_white,
    &_white_hover {
        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: $white !important;
        }
    }

    &_gray,
    &_gray_hover {
        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: $white !important;
        }
    }
}
