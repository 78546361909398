.container {
    &_full-width {
        width: 100%;
    }

    &_max-width {
        &_literal-min {
            max-width: #{$container_max-width_literal-min / $font-size-base}em;
        }
    }
}
