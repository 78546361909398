@import "../variables";

.margin-spacing {
    &_vertical {
        margin-top   : #{$padding-cln};
        margin-bottom: #{$padding-cln};
    }

    &_horizontal {
        margin-right: #{$padding-cln};
        margin-left : #{$padding-cln};
    }

    &_top {
        margin-top: #{$padding-cln};
    }

    &_top_max {
        margin-top: #{$large-margin};
    }

    &_bottom {
        margin-bottom: #{$padding-cln};
    }

    &_right {
        margin-right: #{$padding-cln};
    }

    &_left {
        margin-left: #{$padding-cln};
    }
}

.mt-1 {
    margin-top: $spacer*.25 !important;
}

.mt-2 {
    margin-top: $spacer*.5 !important;
}

.mt-3 {
    margin-top: $spacer*1 !important;
}

.mt-4 {
    margin-top: $spacer*1.5 !important;
}

.mt-5 {
    margin-top: $spacer*3 !important;
}

.mb-1 {
    margin-bottom: $spacer*.25 !important;
}

.mb-2 {
    margin-bottom: $spacer*.5 !important;
}

.mb-3 {
    margin-bottom: $spacer*1 !important;
}

.mb-4 {
    margin-bottom: $spacer*1.5 !important;
}

.mb-5 {
    margin-bottom: $spacer*3 !important;
}

.ml-1 {
    margin-left: $spacer*.25 !important;
}

.ml-2 {
    margin-left: $spacer*.5 !important;
}

.ml-3 {
    margin-left: $spacer*1 !important;
}

.ml-4 {
    margin-left: $spacer*1.5 !important;
}

.ml-5 {
    margin-left: $spacer*3 !important;
}

.mr-1 {
    margin-right: $spacer*.25 !important;
}

.mr-2 {
    margin-right: $spacer*.5 !important;
}

.mr-3 {
    margin-right: $spacer*1 !important;
}

.mr-4 {
    margin-right: $spacer*1.5 !important;
}

.mr-5 {
    margin-right: $spacer*3 !important;
}

.my-auto {
    margin-top   : auto !important;
    margin-bottom: auto !important;
}

.my-1 {
    margin-top   : $spacer*.25 !important;
    margin-bottom: $spacer*.25 !important;
}

.my-2 {
    margin-top   : $spacer*.5 !important;
    margin-bottom: $spacer*.5 !important;
}

.my-3 {
    margin-top   : $spacer*1 !important;
    margin-bottom: $spacer*1 !important;
}

.my-4 {
    margin-top   : $spacer*1.5 !important;
    margin-bottom: $spacer*1.5 !important;
}

.my-5 {
    margin-top   : $spacer*3 !important;
    margin-bottom: $spacer*3 !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left : auto !important;
}

.mx-1 {
    margin-right: $spacer*.25 !important;
    margin-left : $spacer*.25 !important;
}

.mx-2 {
    margin-right: $spacer*.5 !important;
    margin-left : $spacer*.5 !important;
}

.mx-3 {
    margin-right: $spacer*1 !important;
    margin-left : $spacer*1 !important;
}

.mx-4 {
    margin-right: $spacer*1.5 !important;
    margin-left : $spacer*1.5 !important;
}

.mx-5 {
    margin-right: $spacer*3 !important;
    margin-left : $spacer*3 !important;
}