@import "../variables";

// Utils to add literal spacing
.literal-spacing {
    // PADDING AREA START
    padding: $literal-spacing;

    &_gt-xs {
        @media (min-width: $screen-sm-min) {
            padding: $literal-spacing;
        }
    }

    &_md {
        padding: $literal-spacing_med;
    }

    &_min {
        padding: $literal-spacing_min;
    }

    &_max {
        padding: $literal-spacing_max;
    }
    &_max_plus {
        padding: $literal-spacing_max_plus;
    }

    &_vertical {
        padding-top: $literal-spacing;
        padding-bottom: $literal-spacing;

        &_med {
            padding-top: $literal-spacing_med;
            padding-bottom: $literal-spacing_med;
        }

        &_max {
            padding-top: $literal-spacing_max;
            padding-bottom: $literal-spacing_max;
        }
        &_max_plus {
            padding-top: $literal-spacing_vertical_max_plus;
            padding-bottom: $literal-spacing_vertical_max_plus;
        }

        &_min {
            padding-top: $literal-spacing_min;
            padding-bottom: $literal-spacing_min;
        }

        &_xs {
            @media (max-width: $screen-xs-max) {
                padding-top: $literal-spacing;
                padding-bottom: $literal-spacing;
            }
        }

        &_gt-xs {
            @media (min-width: $screen-sm-min) {
                padding-top: $literal-spacing;
                padding-bottom: $literal-spacing;
            }
        }
    }

    &_horizontal {
        padding-left: $literal-spacing;
        padding-right: $literal-spacing;

        &_max {
            padding-left: $literal-spacing_max;
            padding-right: $literal-spacing_max;
        }

        &_min {
            padding-left: $literal-spacing_min;
            padding-right: $literal-spacing_min;
        }

        &_xs {
            @media (max-width: $screen-xs-max) {
                padding-left: $literal-spacing;
                padding-right: $literal-spacing;
            }
        }

        &_gt-xs {
            @media (min-width: $screen-sm-min) {
                padding-left: $literal-spacing;
                padding-right: $literal-spacing;
            }
        }
    }

    &_top {
        padding-top: $literal-spacing;

        &_xs {
            @media(max-width: $screen-xs-max) {
                padding-top: $literal-spacing !important;
            }
        }

        &_sm {
            @media(max-width: $screen-sm-max) {
                padding-top: $literal-spacing !important;
            }
        }

        &_max {
            padding-top: $literal-spacing_max;
        }

        &_min {
            padding-top: $literal-spacing_min;

            &_xs {
                @media(max-width: $screen-xs-max) {
                    padding-top: $literal-spacing_min !important;
                }
            }
        }

        &_gt-xs {
            @media (min-width: $screen-sm-min) {
                padding-top: $literal-spacing;
            }
        }
    }

    &_right {
        padding-right: $literal-spacing;

        &_min {
            padding-right: $literal-spacing_min;
        }

        &_xs {
            @media (max-width: $screen-xs-max) {
                padding-right: $literal-spacing !important;
            }
        }

        &_sm {
            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                padding-right: $literal-spacing !important;
            }
        }
    }

    &_bottom {
        padding-bottom: $literal-spacing;

        &_gt-xs {
            @media (min-width: $screen-sm-min) {
                padding-bottom: $literal-spacing;
            }
        }

        &_min {
            padding-bottom: $literal-spacing_min;
        }
    }

    &_left {
        padding-left: $literal-spacing;

        &_min {
            padding-left: $literal-spacing_min;
        }
    }
    // PADDING AREA END

    // MARGIN AREA START
    &_ext {
        &_bottom {
            margin-bottom: $literal-spacing;
        }
    }
    // MARGIN AREA END
}
