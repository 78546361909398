@import "../../theme/colors";
@import "colors";
@import "css3";
@import "font";

.no-decoration {
    &,
    * {
        text-decoration: none !important;
    }
    &:hover{
        text-decoration: none !important;
    }
}

.cursor {
    cursor: pointer;
}

.text-uppercase * {
    text-transform: uppercase;
}

.text {
    &_spacing-sm {
        letter-spacing: -.01em;
    }

    // === Text Special Style ===
    &-center_xs {
        @media (max-width: $screen-xs-max) {
            text-align: center;

            > * {
                float: none !important;
            }
        }
    }
    // === Text Special Style ===
}
