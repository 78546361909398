.text {
    // === Font Style ===
    &-regular {
        font-weight: 400 !important;
    }

    &-bold {
        font-weight: 700 !important;
    }

    &-italic {
        font-style: italic;
    }
    // === Font Style ===

    // === Font Size ===
    &-xs {
        font-size: .75em;
    }

    &-sm {
        font-size: .875em;
    }

    &_bigger {
        font-size: 1.2em;
    }

    &-lg {
        font-size: 1.33em;
    }

    @for $i from 2 through 10 {
        &-#{$i}x {
            font-size: #{$i}em;

            @media (min-width: $screen-sm-min) {
                &_gt-xs {
                    font-size: #{$i}em;
                }
            }

            @media (min-width: $screen-md-min) {
                &_gt-sm {
                    font-size: #{$i}em;
                }
            }
        }
    }
}
