@import "../../theme/colors";

$separator_min-width: 100px;

#wo-breadcrumbs .separator {
    display: none;
}

.separator {
    border-top         : 0;
    border-right       : 0;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-left        : 0;

    &_yellow {
        border-bottom-color: $yellow;
    }

    &_white-darker {
        border-bottom-color: $white-darker;
    }

    &_min {
        display: block;
        margin : auto;
        width  : $separator_min-width;
    }

    &_thin {
        border-bottom-width: 1px;
    }

    &_spacing {
        margin-top   : #{$padding-cln};
        margin-bottom: #{$padding-cln};
    }

    &_under {
        border-bottom-width: 2px;
        border-style       : solid;
        width              : 70px;

        &_thin {
            border-bottom-width: 1px;

            &_blue {
                border-bottom-color: $color-sfvp-primary;
            }
        }
    }
}