/*
*   name           SFVP - Espace mémoire
*   version        1.0
*   copyright      Copyright (C) 2017 CFD Technologies. All Rights Reserved.
*   license        Licensed under GNU/GPLv3 (http://www.gnu.org/licenses/gpl-3.0.html)
*/
@charset "UTF-8";


// Font size
$font-size-root: 10px;
$font-size-base: 14px;
$font-size-btn-lg: 17px;

$font-size-h2: 18px;

// Spacing
$padding-cln: 15px;
$section-separator-width: 56px;

// Selectors
$titles-selector: '';
@for $i from 1 through 5 {
  $titles-selector : $titles-selector + 'h' + $i + ', .like-h' + $i + ',';
}
$titles-selector: $titles-selector + ' h6, .like-h6';

$top-border-height: 20px;

// margin
$very-large-margin: 80px;
$large-margin: 50px;

// Spacing
$spacer: 1rem;
$literal-spacing: $padding-cln / 3 * 4; // 20px
$literal-spacing_max: $padding-cln * 2; // 30px
$literal-spacing_min: $padding-cln / 3; // 5px
$literal-spacing_med: 7px; //$literal-spacing_min * 2; // 5px
$literal-spacing_max_plus: $padding-cln * 5; // 30px
$literal-spacing_vertical_max_plus: $padding-cln * 7; // 30px

// Content
$content-max-width: 1200px;
$container-fluid-padding-vertical: 50px;

$container-fluid-padding-min: 20px;
$container-fluid-padding-max: 200px;

// Container max width
$container_max-width_literal-min: 720px;
