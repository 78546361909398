.bloc_btn {
  max-width: 300px;
  border-radius: 15px;
  margin: 20px auto;
  padding: 10px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;

    .img-ctn {
      width: 45px;
      margin: 0 10px 0 20px;
      justify-content: center;
      display: flex;

      img {
        max-height: 3em;
      }
    }

    .text-ctn {
      width: 100%;

      p {
        text-align: center;
        color: $white;
        font-size: 16px;
        line-height: 1.5em;
        margin: 0;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }
}