.block_404{
    position: relative;
    padding: 7em 0 7em 0;
    &_content{
        position: relative;
        z-index: 2;
    }
    &_separator{
        margin: 1em auto 3em auto;
    }
    &_title{
        color: $color-sfvp-primary;
        font-size: 7em;
        font-family: 'Montserrat', sans-serif;
        margin: 0;
    }
    &_description{
        margin-bottom: 1.5em;
        font-size: 1.2em;
    }
    &_img{
        position: absolute;
        top : 38%;
        left: 69%;
        transform: translate(-50%, -50%);
        z-index: 1;
        
        @media (max-width: $screen-sm-max) {
            opacity: 0.6;
        }
    }
    a{
        font-size: 1.2em;
        border-radius: 2px;
    }
}