p {
  font-family: $font-sfvp-muli;
  color: $gray-dark;
  font-size: $fa-font-size-base;
  &.reverse {
    color:$white ;
  }
  &.primary {
    color: $color-sfvp-primary;
  }
  &.primary-darker {
    color: $color-sfvp-darker;
  }
  &.italic {
    font-style: italic;
  }
}
h1 {
  color: $color-sfvp-darker;
  font-size: $font-sfvp-size-h1;
  font-weight: bold;
}
h2, h2.titre_normal {
  font-size: $font-sfvp-size-head;
  //line-height: 40px;
  color: $color-sfvp-primary;
  font-family: $font-sfvp-muli;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;

  .bigger {
    font-size: 1.2*$font-sfvp-size-head;
  }
  &.reverse {
    color: $white;
  }
}

h3, .h3 {
  color: $color-sfvp-primary;
  font-weight: bold;

  &.reverse {
    color: $white;

  }
}

h4, .h4 {
  color: $color-sfvp-primary;
  font-size: 20px;
  font-family: $font-sfvp-muli;
  line-height: 40px;
  text-transform: uppercase;
}

h5, .h5 {
  color: $color-sfvp-primary;
  font-family: $font-sfvp-muli;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 20px;
  &.reverse {
    color: $white;
  }
  &.bigger {
    color: $color-sfvp-darker;
    font-size: $font-sfvp-size-head;
    padding-left: 25px;
  }
}

.title {
  color: $color-sfvp-darker;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 27px;
  line-height: 1;
  font-weight: normal;
}

.price {
  font-size: 50px;
}
.underline_primary {
  text-decoration: underline;
  color: $color-sfvp-primary;
}

a:hover > span:before {
    text-decoration: none!important;
}