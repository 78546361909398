@import "../../theme/colors";

.border {
    // ===== Colors =====
    &_white {
        &_thick {
            border: 2px solid #{$white};
        }

        &_bottom {
            border-bottom: 1px solid rgba($white, .5);

            &_only {
                border-top: 0;
                border-right: 0;
                border-bottom: 1px solid rgba($white, .5);
                border-left: 0;
            }
        }
    }

    &_primary_left {
        border-left: 1px solid $color-sfvp-primary;
    }

    &_white-darker {
        border: 1px solid #{$white-darker};
    }

    &_white-dark {
        border: 2px solid #{$white-dark};
    }

    &_gray {
        border: 1px solid #{$gray};

        &_bottom {
            border-bottom: 1px solid #{$gray};
        }
    }

    &_green {
        &_left {
            &_3x {
                border-left: 3px solid #{$green};
            }
        }
    }

    // ===== Styles =====
    &_dashed {
        border-style: dashed;
    }

    // ===== Width =====
    &_2x {
        border-width: 2px;
    }
}
